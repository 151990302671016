import { _ } from "vue-underscore";

export default {
    data: () => ({
        content: '',
        backUrl: '/email-sender',
        emailSender: {
            id: 0,
            name: "",
            email: "",

        },
    }),

    methods: {
        validateEmailSender() {
            this.emailSender.id > 0 ? this.update() : this.add();
        },
        update() {
            let _vm = this;
            this.axios
                .post("/marketing-email-senders/" + this.emailSender.id, {
                    ...this.emailSender,
                    _method: "PATCH"
                })
                .then(function (response) {
                    _vm.emailSender = response.data.data;
                    _vm.$router.push(_vm.backUrl);
                })
                .catch(function () { });
        },
        add() {
            let _vm = this;
            this.axios
                .post("/marketing-email-senders", _vm.emailSender)
                .then(function (response) {
                    _vm.$router.push(_vm.backUrl);
                })
                .catch(function () { });
        },
        getDetail() {
            let _vm = this;
            this.axios
                .get("/marketing-email-senders/" + this.$route.params.id)
                .then(function (response) {
                    _vm.emailSender = response.data.data;
                })
                .catch(function () { });
        },
    },
    mounted() {
        if (this.$route.params.id > 0) {
            this.getDetail();
        }
    }
};
